import { AgdirCardComponent } from '@agdir/core/angular';
import { OfflineDetectComponent, PwaModule } from '@agdir/layout';
import { AnalyticsService, AuthService, CUSTOMER_PATHS, GtagSetProps } from '@agdir/services';
import { SkeletonBarComponent } from '@agdir/ui/loaders';
import { AsyncPipe, DOCUMENT, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { SentryWrapperService } from '@agdir/sentry';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
	standalone: true,
	selector: 'agdir-root',
	template: `
		<ngx-loading-bar [fixed]="true" [includeSpinner]="false" color="#405c70" height="10px"></ngx-loading-bar>
		<router-outlet></router-outlet>
		<agdir-pwa-update-popup></agdir-pwa-update-popup>
		<agdir-pwa-install-popup></agdir-pwa-install-popup>
		<agdir-offline-detect class="bg-warn bottom-0 fixed px-10 py-2 text-center text-blue-50 w-full z-10" />

		<div *ngIf="isLoading()" class="route-change-progress">
			<a href="{{ MY_FARMS_URL }}" target="_self" class="mb-5 z-50">
				<img alt="Agdir logo" class="w-16" src="assets/logo/logo-color.svg" />
			</a>
			<agdir-skeleton-bar />
			<agdir-skeleton-bar />
			<agdir-skeleton-bar />
		</div>
	`,
	styles: [
		`
			:host {
				@apply block;
			}

			.route-change-progress::before {
				display: block;
				content: '';
				position: fixed;
				top: 0;
				left: 0;
				width: 100vw;
				height: 100vh;
				backdrop-filter: blur(5px);
			}
		`,
		`
			.route-change-progress {
				@apply fixed inset-0 flex flex-col justify-center items-center  z-50;
			}
		`,
		`
			.route-change-progress agdir-skeleton-bar {
				@apply w-80 h-8 mb-3;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LoadingBarModule, AsyncPipe, NgIf, RouterOutlet, PwaModule, SkeletonBarComponent, AgdirCardComponent, OfflineDetectComponent],
})
export class AppComponent {
	MY_FARMS_URL = CUSTOMER_PATHS.ALL_FARMS;
	isLoading = signal(false);

	private readonly translate = inject(TranslocoService);
	private readonly document = inject(DOCUMENT);
	private readonly router = inject(Router);
	private readonly analyticsService = inject(AnalyticsService);
	private readonly authService = inject(AuthService);
	private readonly sentry = inject(SentryWrapperService, { optional: true });
	private readonly cognitoUser$ = toSignal(this.authService.getCurrentCustomerSession());

	constructor() {
		this.init();
		effect(() => {
			const u = this.cognitoUser$();
			if (u?._id) {
				this.sentry?.setUserContext(u);
				this.analyticsService.gtagSet(GtagSetProps.USER_ID, u._id);
			}
		});
	}

	private init() {
		this.translate.langChanges$.subscribe(() => {
			this.document.documentElement.lang = this.translate.getActiveLang();
		});

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.isLoading.set(true);
			}
			if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
				this.isLoading.set(false);
			}
		});
	}
}
