import { Injectable } from '@angular/core';
import { Customer } from '@agdir/services';
import * as Sentry from '@sentry/angular';
import { AgdirSentryEnvironment } from './agdir-sentry-environment';

@Injectable()
export class SentryWrapperService {
	static init(e: AgdirSentryEnvironment) {
		Sentry.init(e);
	}

	static forRoot() {
		return {
			provide: SentryWrapperService,
			useClass: SentryWrapperService,
		};
	}

	setUserContext(customer: Customer): void {
		Sentry.setUser({
			id: customer._id,
			phone: customer.phone,
			email: customer.email,
			name: customer.name,
			surname: customer.surname,
		});
	}
}
