import { AgdirApiEnvironment, AgdirAwsEnvironment, AgdirGoogleEnvironment, AgdirOneSignalEnvironment, Environment } from '@agdir/environment/domain';
import { weHaveSharedMfas } from './we-have-shared-mfas';
import { weHaveSharedVendors } from './we-have-shared-vendors';
import * as Sentry from '@sentry/angular';
import { AgdirSentryEnvironment } from '@agdir/sentry';

export const environment = new Environment({ env: 'stg', production: true });
environment
	.register(
		new AgdirAwsEnvironment({
			region: 'eu-west-1',
			userPoolId: 'eu-west-1_Og3uNK3ju',
			userPoolWebClientId: '7mm8mb0mf70uh1pt8fggs6hdp2',
		}),
	)
	.register(
		new AgdirApiEnvironment({
			webHost: 'https://stg.agdir.dev',
			hostv3: 'https://v3.api.stg.agdir.dev',
			host: 'https://api.stg.agdir.dev',
		}),
	)
	.register(
		new AgdirGoogleEnvironment({
			apiKey: 'AIzaSyAd79l2jlY5ekNi-oX7lpbJbtNt6iP9_1c',
			reCaptchaV3SiteKey: '6LftOU0hAAAAAGvXGVjMOi-PUUxs6luj9GNiWp5G',
			googleAnalyticsTrackingCode: 'G-Q671GR7GRR',
		}),
	)
	.register(new AgdirOneSignalEnvironment({ appId: '2a23cf1f-ff12-449e-92a0-da0716d53ff9' }))
	.register(
		new AgdirSentryEnvironment({
			dsn: 'https://d9c2ecb049d8d95897c9c1b22f27c159@o4506693815762944.ingest.us.sentry.io/4507724469501952',
			integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
			tracesSampleRate: 1.0,
			tracePropagationTargets: [],
			// tracePropagationTargets: [/^https:\/\/api\.stg\.agdir\.dev/],
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		}),
	);

weHaveSharedMfas(environment);
weHaveSharedVendors(environment);
